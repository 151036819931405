//Landing.js
import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { Logo } from '../components/Logo';
import SpinnerFull from '../components/SpinnerFull';
import { motion, AnimatePresence } from 'framer-motion';
import { AuthSection } from '../components/AuthSection';
import PricingSection from '../components/PricingSection';
import axios from 'axios';
import { AuthDialog } from '../components/AuthDialog';
import { isJUCE } from '../context/JUCE';

const PartnerCarousel = () => {
  const partners = [
    {
      name: "Beatclub",
      logo: "https://store.soundware.io/wp-content/uploads/2025/01/1.png",
      alt: "Beatclub Logo",
      isDark: false,//
    },
    {
      name: "Soundtrack Loops",
      logo: "https://store.soundware.io/wp-content/uploads/2025/01/STL-logo-white.png",
      alt: "Soundtrack Loops Logo",
      isDark: false
    },
    {
      name: "AudioTech Hub",
      logo: "https://store.soundware.io/wp-content/uploads/2025/01/Audio-tech-hub-logo.png",
      alt: "AudioTech Hub Logo",
      isDark: false
    },
    {
      name: "Infinit Audio",
      logo: "https://store.soundware.io/wp-content/uploads/2025/01/Infinitaudio-white.png",
      alt: "Infinit Audio Logo",
      isDark: false
    },
    {
      name: "Flosstradamus",
      logo: "https://store.soundware.io/wp-content/uploads/2025/01/3.png",
      alt: "Flosstradamus Logo",
      isDark: false,
    }
  ];

  return (
    <section className="relative py-24 md:py-32 overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-bg-primary" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-bg-secondary/10 to-transparent" />
      </div>
      
      <div className="relative max-w-7xl mx-auto px-6 sm:px-8 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-5xl sm:text-6xl lg:text-7xl font-semibold tracking-tight text-text-primary">
            Trusted By{' '}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
              Industry Leaders
            </span>
          </h2>
          <p className="mt-6 text-lg sm:text-xl lg:text-2xl text-text-secondary/80 font-light max-w-2xl mx-auto">
            Partnered with the world's leading music technology companies and artists
          </p>
        </motion.div>

        <div className="relative">
          <motion.div 
            className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 xs:gap-4 sm:gap-6"
          >
            {partners.map((partner, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="flex flex-col items-center mb-8 sm:mb-0"
              >
                <motion.div 
                  className={`group relative h-40 xs:h-32 sm:h-32 w-full rounded-3xl p-8 xs:p-6 sm:p-6 ${
                    partner.isDark 
                      ? "bg-white/20" 
                      : "bg-bg-secondary/30"
                  } backdrop-blur-md border border-white/10 transition-all duration-300`}
                  whileHover={{
                    scale: 1.02,
                    borderColor: "rgba(255, 255, 255, 0.2)",
                  }}
                >
                  {/* Subtle gradient overlay */}
                  <div className="absolute inset-0 rounded-3xl bg-gradient-to-b from-white/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  
                  {/* Partner logo */}
                  <div className="relative h-full flex items-center justify-center px-6 xs:px-4">
                    <img
                      src={partner.logo}
                      alt={partner.alt}
                      className={`max-w-full max-h-full object-contain ${
                        partner.isDark 
                          ? "filter brightness-0 invert" 
                          : "filter brightness-90 group-hover:brightness-100"
                      } ${partner.name !== "AudioTech Hub" ? "scale-100 md:scale-150" : ""} transition-all duration-300 ${partner.className || ""}`}
                    />
                  </div>
                </motion.div>
                
                {/* Partner name with enhanced mobile typography */}
                <motion.p 
                  className="mt-5 xs:mt-4 sm:mt-3 text-lg sm:text-xl font-light text-text-secondary/80 group-hover:text-text-secondary transition-colors duration-300"
                >
                  {partner.name}
                </motion.p>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

// Animation variants
const sectionVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut"
    }
  }
};

const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut"
    }
  },
  hover: {
    y: -8,
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  }
};

const GradientButton = ({ children, onClick, className = "" }) => {
  const buttonRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    // Check if device is mobile (iOS or Android)
    const checkMobile = () => {
      const ua = navigator.userAgent;
      return /android|iphone|ipad|ipod/i.test(ua);
    };
    setIsMobileDevice(checkMobile());
  }, []);

  const handleMouseMove = (e) => {
    if (!buttonRef.current || isMobileDevice) return;
    
    const rect = buttonRef.current.getBoundingClientRect();
    setPosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  // Simple button for mobile devices
  if (isMobileDevice) {
    return (
      <motion.button
        whileTap={{ scale: 0.98 }}
        onClick={onClick}
        className={`relative overflow-hidden ${className}`}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-accent-start to-accent-end" />
        <div className="relative px-8 py-3 font-medium">
          {children}
        </div>
      </motion.button>
    );
  }

  // Interactive button for desktop
  return (
    <motion.button
      ref={buttonRef}
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      initial={{ opacity: 0.9 }}
      whileHover={{ opacity: 1 }}
      whileTap={{ scale: 0.98 }}
      onClick={onClick}
      className={`relative overflow-hidden ${className}`}
    >
      <div className="absolute inset-0 bg-gradient-to-r from-accent-start to-accent-end" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isHovered ? 1 : 0 }}
        className="absolute inset-0 transition-opacity duration-300"
        style={{
          background: `radial-gradient(circle 120px at ${position.x}px ${position.y}px, rgba(255,255,255,0), rgba(0,0,0,0.4))`,
          mixBlendMode: 'multiply'
        }}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isHovered ? 1 : 0 }}
        className="absolute inset-0 transition-opacity duration-300"
        style={{
          background: `radial-gradient(circle 50px at ${position.x}px ${position.y}px, rgba(255,255,255,0.2), transparent 100%)`,
        }}
      />
      <div className="relative px-8 py-3 font-medium">
        {children}
      </div>
    </motion.button>
  );
};

const PricingCard = ({ plan, isPopular, onSelect }) => (
  <motion.div
    whileHover={{ y: -8 }}
    className={`relative p-6 rounded-2xl backdrop-blur-xl border ${
      isPopular ? 'bg-gradient-to-b from-accent-start/10 to-accent-end/10 border-accent-start/20' 
      : 'bg-bg-secondary/50 border-white/10'
    }`}
  >
    {isPopular && (
      <div className="absolute -top-4 left-1/2 -translate-x-1/2 px-4 py-1 rounded-full bg-gradient-to-r from-accent-start to-accent-end text-white text-sm">
        Most Popular
      </div>
    )}
    <div className="text-xl font-bold mb-2">{plan.name}</div>
    <div className="flex items-baseline mb-4">
      <span className="text-3xl font-bold">${plan.price}</span>
      <span className="text-text-secondary">/month</span>
    </div>
    <div className="text-text-secondary text-lg sm:text-xl font-light mb-6">
      {plan.tokens} tokens monthly
    </div>
    <ul className="space-y-3 mb-6">
      {plan.features.map((feature, index) => (
        <li key={index} className="flex items-center text-lg sm:text-xl font-light">
          <span className="mr-2 text-accent-start">✓</span>
          {feature}
        </li>
      ))}
    </ul>
    <GradientButton
      onClick={() => onSelect(plan)}
      className={`w-full rounded-xl ${
        isPopular 
          ? 'hover:shadow-lg hover:shadow-accent-start/20' 
          : 'bg-white/10 text-white hover:bg-white/20'
      }`}
    >
      Choose {plan.name}
    </GradientButton>
  </motion.div>
);

const LandingPage = () => {
  // State management
  const [isLogin, setIsLogin] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    email: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCartLoading, setIsCartLoading] = useState(false);
  const navigate = useNavigate();
  const { loginAndLoadUser, signupAndLoadUser, isLoading } = useContext(AuthContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isAuthDialogOpen, setIsAuthDialogOpen] = useState(false);

  // Refs for scroll targets
  const authSectionRef = useRef(null);
  const soundsSectionRef = useRef(null);
  const pricingSectionRef = useRef(null);
  const creatorSectionRef = useRef(null);
  const demoSectionRef = useRef(null);
  const downloadSectionRef = useRef(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  // First, add a ref for the hero section
  const heroSectionRef = useRef(null);

  // Smooth scroll function
  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      setIsMobileMenuOpen(false);
      setTimeout(() => {
        const yOffset = -80;
        const element = ref.current;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({
          top: y,
          behavior: 'smooth'
        });
      }, 100);
    }
  };

  const handleMenuClick = (sectionRef) => {
    scrollToSection(sectionRef);
  };

  const scrollToTop = () => {
    setIsMobileMenuOpen(false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (shouldScroll) {
      scrollToSection(authSectionRef);
      setShouldScroll(false);
    }
  }, [isLogin, shouldScroll]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    const authMode = searchParams.get('auth_mode');
    
    if (code) {
      console.log('OAuth code received:', code);
      console.log('Auth mode:', authMode);
      // Pass an object with code property to match the credentialResponse structure
      handleGoogleSuccess({ code }, authMode);
    }
  }, []);
  
  // Check if running inside JUCE and automatically open auth dialog in sign-in mode
  useEffect(() => {
    if (isJUCE()) {
      setIsLogin(true);
      setIsAuthDialogOpen(true);
    }
  }, []);

  const handleAuthClick = (loginState) => {
    setIsLogin(loginState);
    setShouldScroll(true);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    try {
      if (isLogin) {
        const result = await loginAndLoadUser(formData.username, formData.password);
        if (result === true) {
          navigate('/');
        } else {
          setError('Login failed: ' + result);
        }
      } else {
        const success = await signupAndLoadUser(
          formData.username,
          formData.password,
          formData.email
        );
        if (success) {
          navigate('/');
        }
      }
    } catch (err) {
      console.error('Auth error:', err);
      // Display the server error message if available
      if (err.message) {
        setError(err.message);
      } else  if (err) {
        setError(err);
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

// In Landing.js, add this utility function
const exchangeCodeForToken = async (code, authMode) => {
  try {
    const tokenResponse = await axios.post('https://oauth2.googleapis.com/token', {
      code: code,
      client_id: '400025157675-9qd00tfa32nc2opvolc1mg5sotecp241.apps.googleusercontent.com',
      client_secret: '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', // You'll need this from Google Console
      redirect_uri: 'https://soundbank.us/landing?auth_mode=' + authMode,
      grant_type: 'authorization_code'
    });
    return tokenResponse.data.id_token; // This is what your backend expects
  } catch (error) {
    console.error('Error exchanging code:', error);
    throw error;
  }
};

const handleGoogleSuccess = async (credentialResponse, authMode) => {
  console.log('Google success response:', credentialResponse);
  try {
    const code = credentialResponse.code;
    console.log('Using code:', code);
    
    // First exchange the code for a token
    const googleToken = await exchangeCodeForToken(code, authMode);
    console.log('Using token:', googleToken);

    // Determine whether to use login or signup endpoint based on authMode
    let response;
    if (authMode === 'signup') {
      console.log('Using signup endpoint for Google auth');
      response = await signupAndLoadUser('', '', '', googleToken);
    } else {
      console.log('Using login endpoint for Google auth');
      response = await loginAndLoadUser('', '', googleToken);
    }
    
    console.log('Auth response:', response);
    if (response) {
      navigate('/');
    }
  } catch (err) {
    console.error('Google auth error:', err);
    setError('Failed to authenticate with Google. ' + (err || err.response?.data?.error || err.message || 'An unexpected error occurred.'));
  }
};
  const handleGoogleError = () => {
    setError('Google sign-in was unsuccessful. Please try again.');
  };

  const features = [
    {
      title: "Unlimited Inspiration",
      description: "A community filled with inspiration and sounds from professional and independent creators around the world.",
      icon: "💡"
    },
    {
      title: "Cross-Platform",
      description: "Available on Mac, Windows, iOS, Web, with Android coming soon. Plus VST/VST3/AU plugin and standalone support.",
      icon: "🔄"
    },
    {
      title: "Access your sounds from anywhere",
      description: "Whether you've uploaded your own private collection or purchased in the marketplace, you'll always have access to your sounds.",
      icon: "📤"
    },
    {
      title: "Pro Audio Tools",
      description: "Time-stretch and pitch-shift samples to match your project's BPM and key. Perfect sync, every time.",
      icon: "🎵"
    }
  ];

  const plans = [
    {
      name: "Starter",
      price: "4.99",
      tokens: 25,
      url: "https://store.soundware.io?add-to-cart=166941",
      features: [
        "25 monthly tokens",
        "1GB sound upload storage",
        "Standalone/VST/AU Plugin"
      ]
    },
    {
      name: "Pro",
      price: "19.99",
      tokens: 150,
      url: "https://store.soundware.io?add-to-cart=166976",
      features: [
        "150 monthly tokens",
        "4GB sound upload storage",
        "Standalone/VST/AU Plugin"
      ]
    },
    {
      name: "Ultimate",
      price: "49.99",
      tokens: 500,
      url: "https://store.soundware.io?add-to-cart=166978",
      features: [
        "500 monthly tokens",
        "10GB sound upload storage",
        "Standalone/VST/AU Plugin"
      ]
    }
  ];

  if (isLoading || isSubmitting) {
    return <SpinnerFull />;
  }

  const handlePlanSelect = (plan) => {
    // Show the spinner before navigation
    setIsCartLoading(true);
    setTimeout(() => {
      window.location.href = plan.url;
    }, 100); // Small timeout to ensure spinner renders
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-bg-primary via-bg-primary/95 to-bg-primary relative overflow-hidden">
      {isCartLoading && <SpinnerFull message="Loading your cart..." />}
      {/* Add a persistent background gradient effect */}
      <div className="fixed inset-0 pointer-events-none">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-accent-start/5 to-transparent opacity-30" />
        <div className="absolute w-full h-full">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[1200px] h-[1200px]">
            <div className="w-full h-full bg-gradient-to-r from-accent-start/10 to-accent-end/10 rounded-full blur-[150px] animate-pulse duration-[5000ms]" />
          </div>
        </div>
      </div>

      {/* Navigation - With added animations */}
      <nav className="fixed w-full z-50 px-4 py-8">
        <div className="max-w-4xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, ease: "easeOut" }}
            className="bg-bg-secondary/30 backdrop-blur-xl border border-white/10 rounded-full px-6 py-3"
          >
            <div className="flex items-center justify-between">
              {/* Logo - Left aligned with animation */}
              <motion.button
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="flex items-center"
                onClick={scrollToTop}
                aria-label="Scroll to top"
              >
                <Logo className="h-6 w-auto" />
              </motion.button>

              {/* Navigation Links - Centered with staggered animation */}
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="hidden md:flex items-center justify-between flex-1 mx-16"
              >
                {[
                  { label: "For Creators", ref: creatorSectionRef },
                  { label: "Sounds", ref: soundsSectionRef },
                  { label: "Pricing", ref: pricingSectionRef },
                  { label: "Download", ref: heroSectionRef }
                ].map((item, index) => (
                  <motion.button 
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, delay: 0.3 + (index * 0.1) }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => handleMenuClick(item.ref)}
                    className="text-text-secondary hover:text-text-primary transition-colors text-base font-medium"
                  >
                    {item.label}
                  </motion.button>
                ))}
              </motion.div>

              {/* Auth Buttons - Right aligned with animation */}
              <div className="hidden md:flex items-center space-x-4">
                <motion.button
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.7 }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    setIsLogin(true);
                    setIsAuthDialogOpen(true);
                  }}
                  className="text-text-primary hover:text-text-secondary transition-colors text-base font-medium"
                >
                  Sign In
                </motion.button>
                <motion.button
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.8 }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    setIsLogin(false);
                    setIsAuthDialogOpen(true);
                  }}
                  className="bg-gradient-to-r from-accent-start to-accent-end text-white px-6 py-2 rounded-full text-base font-medium"
                >
                  Create Account
                </motion.button>
              </div>

              {/* Mobile Menu Button with animation */}
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.4, delay: 0.5 }}
                className="flex md:hidden"
              >
                <button 
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                  className="text-text-secondary hover:text-text-primary p-2 rounded-lg"
                  aria-label="Toggle menu"
                >
                  {!isMobileMenuOpen ? (
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  ) : (
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  )}
                </button>
              </motion.div>
            </div>
          </motion.div>

          {/* Mobile Menu with enhanced animations */}
          <AnimatePresence>
            {isMobileMenuOpen && (
              <motion.div
                initial={{ opacity: 0, y: -20, scale: 0.95 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, y: -20, scale: 0.95 }}
                transition={{ duration: 0.25, ease: "easeOut" }}
                className="md:hidden absolute left-4 right-4 mt-2 p-4 bg-bg-secondary/95 backdrop-blur-xl rounded-2xl border border-white/10"
              >
                <div className="px-4 py-4 space-y-4">
                  {[
                    { label: "For Creators", ref: creatorSectionRef },
                    { label: "Sounds", ref: soundsSectionRef },
                    { label: "Pricing", ref: pricingSectionRef },
                    { label: "Download", ref: heroSectionRef }
                  ].map((item, index) => (
                    <motion.button
                      key={index}
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.05, duration: 0.2 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => handleMenuClick(item.ref)}
                      className="block w-full text-left text-text-secondary hover:text-text-primary transition-colors px-4 py-2 rounded-lg hover:bg-white/5 active:bg-white/10"
                    >
                      {item.label}
                    </motion.button>
                  ))}

                  <div className="grid grid-cols-2 gap-4 pt-2">
                    <motion.button
                      initial={{ opacity: 0, y: 5 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.2, duration: 0.3 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => {
                        setIsLogin(true);
                        setIsAuthDialogOpen(true);
                        setIsMobileMenuOpen(false);
                      }}
                      className="w-full bg-bg-secondary/50 text-text-primary px-6 py-2 rounded-full font-medium border border-white/10 hover:bg-bg-secondary/70 transition-colors active:bg-bg-secondary/90"
                    >
                      Sign In
                    </motion.button>
                    <motion.button
                      initial={{ opacity: 0, y: 5 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.25, duration: 0.3 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => {
                        setIsLogin(false);
                        setIsAuthDialogOpen(true);
                        setIsMobileMenuOpen(false);
                      }}
                      className="w-full bg-gradient-to-r from-accent-start to-accent-end text-text-primary px-6 py-2 rounded-full font-medium active:opacity-90"
                    >
                      Create Account
                    </motion.button>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </nav>

{/* Hero Section - With faster but still elegant fade-in animations */}
<section 
  ref={heroSectionRef} 
  className="relative min-h-[90vh] w-full flex items-center pt-16 sm:pt-36 md:pt-40"
>
  {/* Background with faster fade */}
  <div className="absolute inset-0 overflow-hidden">
    <div className="absolute inset-0 bg-gradient-to-b from-bg-primary via-bg-primary to-bg-secondary/30" />
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.6 }}
      transition={{ duration: 0.8 }}
      className="absolute w-full h-full"
    >
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[800px] h-[800px]">
        <div className="w-full h-full bg-gradient-to-r from-accent-start/5 to-accent-end/5 rounded-full blur-[80px]" />
      </div>
    </motion.div>
  </div>

  <div className="relative w-full max-w-6xl mx-auto px-4 mt-12 sm:mt-0">
    <div className="flex flex-col items-center text-center gap-6 md:gap-12">
      {/* Main heading - faster fade-in */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ 
          duration: 0.7,
          ease: "easeOut"
        }}
        className="max-w-3xl"
      >
        <h1 className="text-4xl sm:text-5xl lg:text-7xl font-semibold tracking-tight text-text-primary">
          Create. Share.{' '}
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
            Earn.
          </span>
        </h1>
        <p className="mt-3 sm:mt-6 text-base sm:text-lg lg:text-xl text-text-secondary/80 font-light max-w-2xl mx-auto">
          A community filled with inspiration and sounds from professional and independent creators around the world.
        </p>
      </motion.div>

      {/* CTA button - faster fade-in with shorter delay */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ 
          duration: 0.6, 
          delay: 0.4,
          ease: "easeIn" 
        }}
      >
        <GradientButton
          onClick={() => {
            setIsLogin(false);
            setIsAuthDialogOpen(true);
          }}
          className="rounded-full text-white hover:shadow-lg hover:shadow-accent-start/20 transition-all duration-300"
        >
          Create Free Account
        </GradientButton>
      </motion.div>

      {/* Product preview image - faster fade-in with shorter delay */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ 
          duration: 0.8,
          delay: 0.6,
          ease: "easeOut"
        }}
        className="w-full max-w-4xl mx-auto relative"
      >
        <img
          src="https://store.soundware.io/wp-content/uploads/2025/02/Screenshot-2025-02-19-at-10.36.34 PM-1.png"
          alt="SoundBank Interface"
          className="relative w-full h-auto rounded-xl shadow-lg"
          loading="eager"
        />
      </motion.div>

      {/* Download buttons - faster fade-in with shorter delay */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ 
          duration: 0.6, 
          delay: 1.0,
          ease: "easeIn" 
        }}
        className="download-section flex flex-col sm:flex-row items-center justify-center gap-2 sm:gap-4 mt-4 sm:mt-8 mb-6 sm:mb-16"
      >
        <motion.a
          href="https://f002.backblazeb2.com/file/soundwarereleases/SoundBank%20Win%20Installer.exe"
          whileTap={{ scale: 0.98 }}
          className="w-64 h-14 flex items-center justify-center gap-3 rounded-full bg-[#1e1e1e] text-white font-medium border border-white/10 hover:bg-[#2a2a2a] transition-all duration-300"
        >
          <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
            <path d="M0 3.449L9.75 2.1v9.451H0m10.949-9.602L24 0v11.4H10.949M0 12.6h9.75v9.451L0 20.699M10.949 12.6H24V24l-12.9-1.801"/>
          </svg>
          <span>Download for Windows</span>
        </motion.a>

        <motion.a
          href="https://f002.backblazeb2.com/file/soundwarereleases/SoundBank%20Mac%20Installer_signed.pkg"
          whileTap={{ scale: 0.98 }}
          className="w-64 h-14 flex items-center justify-center gap-3 rounded-full bg-[#1e1e1e] text-white font-medium border border-white/10 hover:bg-[#2a2a2a] transition-all duration-300"
        >
          <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
            <path d="M22 17.607c-.786 2.28-3.139 6.317-5.563 6.361-1.608.031-2.125-.953-3.963-.953-1.837 0-2.412.923-3.932.983-2.572.099-6.542-5.827-6.542-10.995 0-4.747 3.308-7.1 6.198-7.143 1.55-.028 3.014 1.045 3.959 1.045.949 0 2.727-1.29 4.596-1.101.782.033 2.979.315 4.389 2.377-3.741 2.442-3.158 7.549.858 9.426zm-5.222-17.607c-2.826.114-5.132 3.079-4.81 5.531 2.612.203 5.118-2.725 4.81-5.531z"/>
          </svg>
          <span>Download for macOS</span>
        </motion.a>
      </motion.div>
    </div>
  </div>

  <AuthDialog
    isOpen={isAuthDialogOpen}
    onClose={() => setIsAuthDialogOpen(false)}
    isLogin={isLogin}
    setIsLogin={setIsLogin}
    formData={formData}
    handleInputChange={handleInputChange}
    handleSubmit={handleSubmit}
    error={error}
    isSubmitting={isSubmitting}
    handleGoogleSuccess={handleGoogleSuccess}
    handleGoogleError={handleGoogleError}
  />
</section>

     {/* Creator Section - Enhanced styling */}
<section ref={creatorSectionRef} className="relative py-32">
  <div className="absolute inset-0">
    <div className="absolute inset-0 bg-gradient-to-b from-transparent via-bg-secondary/5 to-transparent" />
    <div className="absolute w-full h-full">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[1000px] h-[1000px]">
        <div className="w-full h-full bg-gradient-to-r from-accent-start/5 to-accent-end/5 rounded-full blur-[120px]" />
      </div>
    </div>
  </div>
  <div className="relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={sectionVariants}
      className="text-center mb-32"
    >
      <h2 className="text-5xl sm:text-6xl lg:text-7xl font-semibold tracking-tight text-text-primary">
        Make Sounds,{' '}
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
          Make Money
        </span>
      </h2>
      <p className="mt-6 text-lg sm:text-xl lg:text-2xl text-text-secondary/80 font-light max-w-2xl mx-auto ">
        Turn your sounds into income
      </p>
    </motion.div>

    <div className="grid md:grid-cols-2 gap-12 lg:gap-20 items-center">
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
        className="flex flex-col items-center md:items-start px-4 lg:px-0"
      >
        <h3 className="text-4xl sm:text-5xl font-medium mb-8 text-text-primary text-center md:text-left w-full">
          Start Earning From Your{' '}
            Creativity
        </h3>
        <ul className="space-y-4 mb-12 flex flex-col items-center md:items-start w-full">
          {[
            "Upload, store, and\npublish your sounds",
            "Set your own prices",
            "Keep 70% of every sale",
            "Cash out anytime",
            "Build your brand",
            "Real-time analytics",
            "Control your royalties"
          ].map((item, index) => (
            <motion.li
              key={index}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="flex items-start text-text-secondary text-lg opacity-80 whitespace-pre-line"
            >
              <div className="flex items-start min-w-[260px] md:min-w-0 translate-x-6 md:translate-x-0">
                <span className="text-[#40E6FF] mr-2 text-xl mt-[2px]">✓</span>
                <span>{item}</span>
              </div>
            </motion.li>
          ))}
        </ul>

        <GradientButton
          onClick={() => {
            setIsLogin(false);
            setIsAuthDialogOpen(true);
          }}
          className="rounded-full text-white hover:shadow-lg hover:shadow-accent-start/20 transition-all duration-300"
        >
          Start Selling
        </GradientButton>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: 20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
        className="relative flex justify-center"
      >
        <div className="relative max-w-full lg:max-w-[120%] lg:ml-[-10%]">
          <img
            src="https://store.soundware.io/wp-content/uploads/2025/02/soundbank-user-profile-3d-1-1.png"
            alt="Creator Dashboard"
            className="w-full"
          />
        </div>
      </motion.div>
    </div>
  </div>
</section>
      {/* Features Section - Enhanced styling */}
      <section ref={soundsSectionRef} className="relative py-0 md:py-32">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-bg-secondary/5 to-transparent" />
        </div>
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={sectionVariants}
            className="text-center mb-16 md:mb-32"
          >
            <h2 className="text-5xl sm:text-6xl lg:text-7xl font-semibold tracking-tight text-text-primary">
              The World's First
              <br />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
                Social Media VST
              </span>
            </h2>
            <p className="mt-6 text-lg sm:text-xl lg:text-2xl text-text-secondary/80 font-light max-w-2xl mx-auto">
              Your global music community at the click of a plugin
            </p>
          </motion.div>

          <div className="grid lg:grid-cols-2 gap-16 items-center mb-24">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="relative group"
            >
              <div className="absolute inset-0" />
              <img
                src="https://store.soundware.io/wp-content/uploads/2025/02/Screenshot-2025-02-19-at-11.10.16 PM-1.png"
                alt="SoundBank Plugin Interface"
                className="relative rounded-xl w-full shadow-lg"
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="space-y-8"
            >
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial="hidden"
                  whileInView="visible"
                  whileHover="hover"
                  viewport={{ once: true }}
                  variants={cardVariants}
                  transition={{ delay: index * 0.2 }}
                  className="p-6 rounded-xl backdrop-blur-sm bg-bg-secondary/5 border border-white/10 hover:border-accent-start/30 transition-colors"
                >
                  <div className="flex items-start space-x-4">
                    <div className="text-3xl">{feature.icon}</div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
                        {feature.title}
                      </h3>
                      <p className="text-text-secondary text-lg sm:text-xl font-light">{feature.description}</p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </div>
      </section>

 {/* Build Your Brand Section */}
 <section className="relative py-0">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-bg-secondary to-transparent" />
        </div>
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={sectionVariants}
            className="text-center mb-32"
          >
            <h2 className="text-5xl sm:text-6xl lg:text-7xl font-semibold tracking-tight text-text-primary">
              Build Your{' '}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
                Brand
              </span>
            </h2>
            <p className="mt-6 text-lg sm:text-xl lg:text-2xl text-text-secondary/80 font-light max-w-2xl mx-auto">
              Grow your audience and maximize your reach
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-12 items-center">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="text-center md:text-left order-2 md:order-1"
            >
             <h3 className="text-4xl sm:text-5xl font-bold mb-6 text-text-primary">
  Take Your Brand to the{' '}
  <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
    Next Level
  </span>
</h3>
<ul className="space-y-4">
  {[
    "Customize your artist profile",
    "Connect with your audience",
    "Share on social media",
    "Track engagement metrics",
    "Get featured in collections",
    "Cross-promote with other artists",
    "Build your following"
  ].map((item, index) => (
    <motion.li
      key={index}
      initial={{ opacity: 0, x: -20 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      transition={{ delay: index * 0.1 }}
      className="flex items-center text-text-secondary justify-center lg:justify-start"
    >
      <span className="mr-2 text-accent-start">✓</span>
      {item}
    </motion.li>
  ))}
</ul>
<div className="mt-8" style={{ marginLeft: '-1px' }}>
  <GradientButton
    onClick={() => {
      setIsLogin(false);
      setIsAuthDialogOpen(true);
    }}
    className="rounded-full text-white hover:shadow-lg hover:shadow-accent-start/20 transition-all duration-300"
  >
    Get Started
  </GradientButton>
</div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="relative flex justify-center order-1 md:order-2"
            >
              <div className="relative max-w-full">
                <img
                  src="https://store.soundware.io/wp-content/uploads/2025/02/Screenshot-2025-02-20-at-12.40.54 AM-1-1.png"
                  alt="Brand Building Dashboard"
                  className="rounded-xl w-full shadow-lg mb-24"
                />
              </div>
            </motion.div>
          </div>
        </div>
      </section>

{/* Sound Packs Section - Enhanced styling */}
<section className="relative py-32">
  <div className="absolute inset-0">
    <div className="absolute inset-0 bg-gradient-to-b from-transparent via-bg-secondary/5 to-transparent" />
  </div>
  
  <div className="relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={sectionVariants}
      className="text-center mb-16 md:mb-32"
    >
      <h2 className="text-5xl sm:text-6xl lg:text-7xl font-semibold tracking-tight text-text-primary">
        Sound{' '}
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
          Packs
        </span>
      </h2>
      <p className="mt-6 text-lg sm:text-xl lg:text-2xl text-text-secondary/80 font-light max-w-2xl mx-auto">
        Premium sound collections for every genre and style
      </p>
    </motion.div>

    <div className="grid lg:grid-cols-2 gap-16 items-center mb-24">
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
        className="relative group"
      >
        <div className="absolute inset-0" />
        <img
          src="https://store.soundware.io/wp-content/uploads/2025/01/Untitled-design-7-min.png"
          alt="Sound Packs Preview"
          className="relative rounded-xl w-full"
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: 20 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
        className="space-y-8"
      >
        {[
          {
            icon: "🎵",
            title: "Create Collections",
            description: "Create your own sound packs or access sounds from top producers and sound designers across all genres"
          },
          {
            icon: "🎚️",
            title: "Studio Quality",
            description: "Professional grade samples recorded and processed with top-tier equipment"
          },
          {
            icon: "🎹",
            title: "Genre Variety",
            description: "From hip-hop to EDM, rock to ambient, find the perfect sounds for your style"
          },
          {
            icon: "🔄",
            title: "Regular Updates",
            description: "New packs added weekly, keeping you inspired with fresh content"
          }
        ].map((feature, index) => (
          <motion.div
            key={index}
            initial="hidden"
            whileInView="visible"
            whileHover="hover"
            viewport={{ once: true }}
            variants={cardVariants}
            transition={{ delay: index * 0.2 }}
            className="p-6 rounded-xl backdrop-blur-sm bg-bg-secondary/5 border border-white/10 hover:border-accent-start/30 transition-colors"
          >
            <div className="flex items-start space-x-4">
              <div className="text-3xl">{feature.icon}</div>
              <div>
                <h3 className="text-xl font-semibold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
                  {feature.title}
                </h3>
                <p className="text-text-secondary text-lg sm:text-xl font-light">{feature.description}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </div>
  </div>
</section>

{/* Partners Section */}
<PartnerCarousel/>

{/* Pricing Section - Enhanced styling */}
<section ref={pricingSectionRef} className="relative py-32">
  <div className="absolute inset-0">
    <div className="absolute inset-0 bg-gradient-to-b from-transparent via-bg-secondary/10 to-transparent" />
  </div>
  <div className="relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={sectionVariants}
      className="text-center mb-32"
    >
      <h2 className="text-5xl sm:text-6xl lg:text-7xl font-semibold tracking-tight text-text-primary">
        Choose Your{' '}
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent-start to-accent-end">
          Plan
        </span>
      </h2>
      <p className="mt-6 text-lg sm:text-xl lg:text-2xl text-text-secondary/80 font-light max-w-2xl mx-auto">
        Start with 25 tokens, upgrade anytime
      </p>
    </motion.div>

    <div className="grid md:grid-cols-3 gap-8 text-white">
      {plans.map((plan, index) => (
        <motion.div
          key={index}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={cardVariants}
          transition={{ delay: index * 0.2 }}
        >
          <PricingCard 
            plan={plan} 
            isPopular={index === 1} 
            onSelect={handlePlanSelect}
          />
        </motion.div>
      ))}
    </div>
  </div>
</section>
{/* System Requirements - Enhanced styling */}
<section className="relative py-32">
  <div className="absolute inset-0">
    <div className="absolute inset-0 bg-gradient-to-b from-transparent via-bg-secondary/10 to-transparent" />
  </div>
  <div className="relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={sectionVariants}
      className="text-center mb-16"
    >
      <h2 className="text-5xl sm:text-6xl lg:text-7xl font-semibold tracking-tight text-text-primary">
        System{' '}
          Requirements
      </h2>
      <p className="mt-6 text-lg sm:text-xl lg:text-2xl text-text-secondary/80 font-light max-w-2xl mx-auto">
        Everything you need to get started
      </p>
    </motion.div>

    <div className="grid md:grid-cols-2 gap-8">
      {/* Windows Requirements */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={cardVariants}
        className="p-6"
      >
        <div className="flex items-center space-x-3 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-text-primary" viewBox="0 0 24 24" fill="currentColor">
            <path d="M0 3.449L9.75 2.1v9.451H0m10.949-9.602L24 0v11.4H10.949M0 12.6h9.75v9.451L0 20.699M10.949 12.6H24V24l-12.9-1.801"/>
          </svg>
          <h3 className="text-2xl font-bold text-text-primary">Windows</h3>
        </div>
        <ul className="space-y-3">
          {[
            'VST or VST3 host software',
            'Pentium class 2.0 GHz processor with SSE3 support',
            '8GB of RAM (16GB or more highly recommended)',
            'Display with 1024-by-768 or higher resolution',
            'Windows 8, Windows 10, and Windows 11',
            'Internet connection',
            '64-bit only'
          ].map((req, index) => (
            <motion.li
              key={index}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="flex items-start space-x-3"
            >
              <span className="text-accent-start mt-1">•</span>
              <span className="text-text-secondary text-lg sm:text-xl font-light">{req}</span>
            </motion.li>
          ))}
        </ul>
      </motion.div>

      {/* macOS Requirements */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={cardVariants}
        className="p-6"
      >
        <div className="flex items-center space-x-3 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-text-primary" viewBox="0 0 24 24" fill="currentColor">
            <path d="M22 17.607c-.786 2.28-3.139 6.317-5.563 6.361-1.608.031-2.125-.953-3.963-.953-1.837 0-2.412.923-3.932.983-2.572.099-6.542-5.827-6.542-10.995 0-4.747 3.308-7.1 6.198-7.143 1.55-.028 3.014 1.045 3.959 1.045.949 0 2.727-1.29 4.596-1.101.782.033 2.979.315 4.389 2.377-3.741 2.442-3.158 7.549.858 9.426zm-5.222-17.607c-2.826.114-5.132 3.079-4.81 5.531 2.612.203 5.118-2.725 4.81-5.531z"/>
          </svg>
          <h3 className="text-2xl font-bold text-text-primary">macOS</h3>
        </div>
        <ul className="space-y-3">
          {[
            'AudioUnit, VST or VST3 host software',
            'Apple Silicon M1 (native) or Intel 2.0 GHz processor',
            '8GB of RAM (16GB or more highly recommended)',
            'Display with 1024-by-768 or higher resolution',
            'macOS 10.13 and later including macOS Ventura',
            'Internet connection',
            '64-bit only'
          ].map((req, index) => (
            <motion.li
              key={index}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="flex items-start space-x-3"
            >
              <span className="text-accent-start mt-1">•</span>
              <span className="text-text-secondary text-lg sm:text-xl font-light">{req}</span>
            </motion.li>
          ))}
        </ul>
      </motion.div>
    </div>
    <div className='h-8'/>
        {/* DAW Compatibility Section */}
        <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="text-center"
    >
      <div className="p-6">
        <img
          src="https://octane.soundware.io/wp-content/uploads/sites/3/2023/08/Daw-Icons-with-bitwig.png.webp"
          alt="DAW Compatibility"
          className="max-w-full h-auto mx-auto rounded-xl shadow-lg"
        />
      </div>
    </motion.div>
  </div>
</section>

      {/* Footer - Enhanced styling */}
      <footer className="relative py-24 border-t border-white/10">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-bg-primary/80" />
        </div>
        <div className="relative max-w-6xl mx-auto px-4">
          {/* Logo and description */}
          <div className="text-center">
            <div className="flex items-center justify-center space-x-4 mb-4">
              <Logo className="h-8 w-auto" />
            </div>
            <p className="text-text-secondary">
              Create. Share. Earn.
            </p>
          </div>
          
          {/* Copyright */}
          <div className="mt-12 pt-8 border-t border-white/10 text-center text-text-secondary">
            <p>© {new Date().getFullYear()} SoundWare, Inc. All rights reserved. <a href="https://store.soundware.io/privacy-policy/" className="hover:text-accent-start transition-colors duration-300">Privacy Policy</a></p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;